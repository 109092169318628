(function ($, window, document, undefined) {
  'use strict';

  window.Utils = {};

  Utils.userAgent = (function() {
    var name = window.navigator.userAgent.toLowerCase(),
    nameFunc = function(){ return name;},
    isIE = function(){ return ( name.indexOf('msie') >= 0 || name.indexOf('trident') >= 0 );},
    isEdge = function(){ return name.indexOf('edge') >= 0;},
    isChrome = function(){ return name.indexOf('chrome') >= 0;},
    isFirefox = function(){ return name.indexOf('firefox') >= 0;},
    isSafari = function(){ return name.indexOf('safari') >= 0;},
    isOpera = function(){ return name.indexOf('opera') >= 0;},
    isiPhone = function(){ return name.indexOf('iphone') >= 0;},
    isiPod = function(){ return name.indexOf('ipod') >= 0;},
    isiPad = function(){ return name.indexOf('ipad') >= 0;},
    isiOS = function(){ return ( isiPhone() || isiPod() || isiPad() );},
    isAndroid = function(){ return name.indexOf('android') >= 0;},
    isAndroidDefault = function(){ return ( isAndroid() && name.indexOf('chrome') < 0 && name.indexOf('firefox') < 0 );},
    // isAndroidTablet = function(){ return ( isAndroid() && name.indexOf('mobile') < 0 );},
    // isAndroidPhone = function(){ return ( isAndroid() && name.indexOf('mobile') >= 0 );},
    isAndroidTablet = function(){ return ( isAndroid() && /.*android.*[0-9]\ssafari.*/.test(name));},
    isAndroidPhone = function(){ return ( isAndroid() && !/.*android.*[0-9]\ssafari.*/.test(name));},
    isWindowsPhone = function(){ return (isIE() && name.indexOf('iemobile') >= 0);},
    isTablet = function(){ return ( isiPad() || isAndroidTablet() );},
    // isSmartPhone = function(){ return ( isiPhone() || isAndroidPhone() || isWindowsPhone() );},
    isSmartPhone = function(){ return ( isiPhone() || isAndroidPhone() );},
    isMobileDevice = function(){ return ( isSmartPhone() || isTablet() );},
    isMac = function(){ return ( name.indexOf('mac') >= 0 || name.indexOf('ppc') >= 0);},
    verArray = function(){ return _getVerArray();};

    function _getVerArray() {
      var array = (function() {
        if (isIE()) {
          return /(msie|rv:?)\s?([0-9]{1,})([\.0-9]{1,})/.exec(name);
        } else if (isiOS()) {
          return /(os)\s([0-9]{1,})([\_0-9]{1,})/.exec(name);
        } else if (isAndroid()) {
          return /(android)\s([0-9]{1,})([\.0-9]{1,})/.exec(name);
        }/* else if (isWindowsPhone()) {
          return /.../.exec(name);
        }*/
      })();

      return (array) ? [parseInt(array[2], 10), array[2] + array[3]] : false;
    }

    //IEバージョン
    var isIEVer = function(targetVer) {
        return (isIE() && verArray()[0] == targetVer);
    },
    //IEバージョン（小なり判定）
    isIEVerLt = function(targetVer) {
        return (isIE() && verArray()[0] < targetVer);
    },
    //IEバージョン（小なりイコール判定）
    isIEVerLte = function(targetVer) {
        return (isIE() && verArray()[0] <= targetVer);
    },
    //IEバージョン（大なり判定）
    isIEVerGt = function(targetVer) {
        return (isIE() && verArray()[0] > targetVer);
    },
    //IEバージョン（大なりイコール判定）
    isIEVerGte = function(targetVer) {
        return (isIE() && verArray()[0] >= targetVer);
    };

    return {
      name: nameFunc,
      isIE: isIE,
      isEdge: isEdge,
      isChrome: isChrome,
      isFirefox: isFirefox,
      isSafari: isSafari,
      isOpera: isOpera,
      isiPhone: isiPhone,
      isiPod: isiPod,
      isiPad: isiPad,
      isiOS: isiOS,
      isAndroid: isAndroid,
      isAndroidDefault: isAndroidDefault,
      isAndroidTablet: isAndroidTablet,
      isAndroidPhone: isAndroidPhone,
      isTablet: isTablet,
      isSmartPhone: isSmartPhone,
      isMobileDevice: isMobileDevice,
      isWindowsPhone: isWindowsPhone,
      isIEVer: isIEVer,
      isIEVerLt: isIEVerLt,
      isIEVerLte: isIEVerLte,
      isIEVerGt: isIEVerGt,
      isIEVerGte: isIEVerGte,
      getVer: verArray[0],
      getVerFull: verArray[1],
      verArray: verArray
    };
  })();

  Utils.getUaList = function() {

    var uaList = [];
    var ua = Utils.userAgent;

    // IE
    if (ua.isIE()) {
        uaList.push('isIE');
        if (ua.isIEVer(11)) { uaList.push('isIE11'); }
        if (ua.isIEVer(10)) { uaList.push('isIE10'); }
        if (ua.isIEVer(9)) { uaList.push('isIE9'); }
        if (ua.isIEVer(8)) { uaList.push('isIE8'); }
        if (ua.isIEVer(7)) { uaList.push('isIE7'); }
        if (ua.isIEVer(6)) { uaList.push('isIE6'); }
        if (ua.isIEVerLte(8)) { uaList.push('isLegacy'); }
        if (ua.isWindowsPhone()) { uaList.push('isWinPhone'); }
    }

    // 他のブラウザ
    if (!ua.isIE()) {
      if (ua.isEdge()) {
        uaList.push('isEdge');
      } else if (ua.isChrome()) {
        uaList.push('isChrome');
      } else if (ua.isFirefox()) {
        uaList.push('isFirefox');
      } else if (ua.isSafari()) {
        uaList.push('isSafari');
      } else if (ua.isOpera()) {
        uaList.push('isOpera');
      }
    }

    // iOS
    if (ua.isiOS()) {
        uaList.push('isiOS');
        if (ua.isiPad()) { uaList.push('isiPad'); }
        if (ua.isiPhone()) { uaList.push('isiPhone'); }
        if (ua.isiPod()) { uaList.push('isiPod'); }
    }

    // android
    if (ua.isAndroid()) {
        uaList.push('isAndroid');
        // android標準ブラウザ
        if(ua.isAndroidDefault()){  uaList.push('isAndroidDefault'); }
        // androidタブレット
        if (ua.isTablet()) {  uaList.push('isAndroidTablet'); }
    }

    // device
    if (ua.isTablet()) { uaList.push('isTablet'); }
    if (ua.isSmartPhone()) { uaList.push('isSmartPhone'); }
    if (ua.isMobileDevice()) { uaList.push('isMobileDevice'); }
    if (!ua.isMobileDevice()) { uaList.push('isOtherDevice'); }
    if (ua.isTablet() || ua.isMobileDevice()) uaList.push('isTouchDevice');

    //Mac
    //if (ua.isMac()) { uaList.push('isMac'); }

    return uaList.join(' ');
  };

  Utils.getOtherClass = function() {
    var classList = [];
    // featurejs
    /*
    if (feature) {
      if (feature.touch) { classList.push('isTouchDevice'); }
    }
    */
    return classList.join(' ');
  };

})(jQuery, window, document);


var site = {};

site.init = function () {
  $('html')
    .removeClass('no-js')
    .addClass('js')
    .addClass(Utils.getUaList())
    .addClass(Utils.getOtherClass())
    //.addClass(Utils.getWinSizeNameList().join(' '));

  site.switchListener();
  
  $(function() {
    // init
    site.fixedHeader();
    site.loading();
  });
};

site.loading = function () {
  $('html').addClass('init');
  $('body').prepend('<div id="js-loading" class="js-loading" style="display: none;"><span><img src="/shared/images/loading-logo.png" alt=""></span></div>');

  var resizeMovie = function () {
    if (!$('html').hasClass('page-index')) return;
    var $w = $(window),
      bw = 1200,
      bh = (bw/16) * 9,
      w = $w.width(),
      h = $w.height(),
      mw = w,
      mh =  Math.round(bh * (mw/bw));
    
    if ( mh < h ) {
      mh = h;
      mw = Math.round(bw * (mh/bh));
    }
    
    $('#movie').css({
      width: mw,
      height: mh,
      marginTop: (h - mh)/2, 
      marginLeft: (w - mw)/2
    });
  }
  
  $(window).resize(resizeMovie).trigger('resize');

  //$('#js-loading').hide();
  site.switchImg();
}

site.switchImg = function () {
  if (window.matchMedia("(min-width:768px)").matches) {
    $('.switchImg').each(function () {
      $(this).attr('src', $(this).attr('data-tablet-src'));
    });
  } else {
    $('.switchImg').each(function () {
      $(this).attr('src', $(this).attr('data-sp-src'));
    });
  }
};
site.switchTel = function () {
  if (window.matchMedia("(min-width:768px)").matches) {
    $('span.tel').each(function () {
      var $isTel = $(this).parent('.sp-tel');
      if ($isTel[0]) {
        $(this).unwrap();
      }
    });
  } else {
    $('span.tel').each(function () {
      var tel = $(this).text().replace(/-/g, '');
      $(this).wrap('<a href="tel:' + tel + '" class="sp-tel"></a>');
    });
  }
};

site.switchListener = function () {
  window.matchMedia("(min-width:768px)").addListener(function () {
    site.switchImg();
    site.switchTel();
    $('html').removeClass('spmenu');
    $('#global-header').removeClass('is-anim is-open');
  });
};

site.fixedHeader = function () {
  var $header = $('#global-header');
  var fixed = false;
  var move = 150;
  $(window).scroll(() => {
    if (!fixed && $(window).scrollTop() > move) {
      $header.addClass('is-fixed');
      fixed = true;
    } else if (fixed && $(window).scrollTop() <= move) {
      $header.removeClass('is-fixed');
      fixed = false;
    }
  });
};

site.init();
/*
 *
 *
 * handler
 *
 *
 */

var focusableElementsString = "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex]:not([tabindex='-1']), *[contenteditable]";

// flying focus
$(document).on('keydown', function(e) {
  if (e.keyCode == 9 || e.keyCode == 37 || e.keyCode == 38 || e.keyCode == 39 || e.keyCode == 40) {
    if (!$('#flying-focus')[0]) {
      $('body').append('<div id="flying-focus" class="flying-focus"></div>');
    }
    setTimeout(function () {
      var $focusEl = $(document.activeElement);
      if ($focusEl[0]) {
        $('#flying-focus').addClass('flying-focus_visible')
        var rect = $focusEl[0].getBoundingClientRect() ;
        var positionX = rect.left + window.pageXOffset;
        var positionY = rect.top + window.pageYOffset;
        var width = $focusEl.outerWidth();
        var height = $focusEl.outerHeight();
    
        $('#flying-focus').css({
          top: positionY + 'px',
          left: positionX + 'px',
          width: width + 'px',
          height: height + 'px'
        });
      }
    }, 10);
  }
});
$(document).on('focusout', function(e) {
  if ($('#flying-focus')[0] && $('#flying-focus').hasClass('flying-focus_visible')) {
    $('#flying-focus').removeClass('flying-focus_visible');
  }
});

var spTimer;
$(document).on('click', '.button-menu', function () {
  var $gHeader = $('#global-header');
  clearTimeout(spTimer);
  if ($gHeader.hasClass('is-open')) {
    $('html').removeClass('spmenu');
    $gHeader.removeClass('is-anim');
    spTimer = setTimeout(() => {
      $gHeader.removeClass('is-open');
    }, 500);
  } else {
    $gHeader.addClass('is-open');
    $('html').addClass('spmenu');
    spTimer = setTimeout(() => {
      $gHeader.addClass('is-anim');
    }, 0);
  }
});

$(document).on('click', '#gnav a', function () {
  var $gHeader = $('#global-header');
  if ($gHeader.hasClass('is-open')) {
    $('.button-menu').trigger('click');
  }
});

$(document).on('click', 'a[href^="#"][data-scroll="true"]', function(e) {
  e.preventDefault();
  var speed = 800;
  var href= $(this).attr('href');
  var $target = $(href == '#' || href == '' ? 'html' : href);
  if ($target[0]) {
    $('body,html').animate({scrollTop: $target.offset().top}, speed, 'easeOutQuint');
  }
});

// tab
$(document).on('keydown', '.js-tablist', function(e) {
  var $this = $(this);
  var $tab = $this.closest('.js-tabs');
  var $lastTab = $tab.find('.js-tablist__item:last-child');
  var $firstTab = $tab.find('.js-tablist__item:first-child');
  var $activeTab = $tab.find('.js-tablist__link[aria-selected="true"]').closest('.js-tablist__item');
  var $nextTab, $prevTab;
  var focusFlg = false;
  
  if ($activeTab.is('.js-tablist__item:last-child')) {
    $nextTab = $firstTab;
    $prevTab = $activeTab.prev();
  } else if ($activeTab.is('.js-tablist__item:first-child')) {
    $nextTab = $activeTab.next();
    $prevTab = $lastTab;
  } else {
    $nextTab = $activeTab.next();
    $prevTab = $activeTab.prev();
  }
  
  // focusが.js-tablist__linkにあるか
  if ($(document.activeElement).is($tab.find('.js-tablist__link'))) {
    focusFlg = true;
  }
  
  if (focusFlg && !e.ctrlKey) {
    // up left
    if (e.keyCode == 37 || e.keyCode == 38) {
      $prevTab.find('.js-tablist__link').click().focus();
      e.preventDefault();
    }
    // down right
    else if (e.keyCode == 40 || e.keyCode == 39) {
      $nextTab.find('.js-tablist__link').click().focus();
      e.preventDefault();
    }
  }
});
$(document).on('click', '.js-tablist__link[aria-disabled="true"]', function(e) {
  return false;
});
$(document).on('click', '.js-tablist__link:not([aria-disabled="true"])', function(e) {
  e.preventDefault();
  
  var $this = $(this);
  var $thisList = $this.closest('.js-tablist__item');
  var $tab = $this.closest('.js-tabs');
  var $allTabList = $tab.find('.js-tablist__item');
  var $allTabLink = $tab.find('.js-tablist__link');
  var $allTabContents = $tab.find('.js-tabcontent');
  var $tabContentLinked = $('#' + $this.attr('aria-controls'));

  $allTabList.removeClass('active');
  $thisList.addClass('active');
  
  $allTabLink.attr({ 
    "tabindex": -1,
    "aria-selected": "false"
  });
  $this.attr({    
    "aria-selected": "true",
    "tabindex": 0
  });
  
  // content
  $allTabContents.attr('aria-hidden', 'true');
  $tabContentLinked.removeAttr('aria-hidden');

  // add fragment (timeout for transitions)
  //setTimeout(function(){ history.pushState(null, null, location.pathname + location.search + '#' + $hash_to_update)}, 1000);
});

// modal
$(document).on('click', '.js-modal-button', function(e) {
  e.preventDefault();
  $('#js-modal-close').focus();
  var $this = $(this);
  var $modal = $('#' + $this.attr('data-modal-target-type'));
  var $modalOverlay = $('#js-modal-overlay');
  page['page-works'].modalOpened.type = $this.attr('data-modal-target-type');
  page['page-works'].modalOpened.id = $this.attr('data-modal-target-id');
  
  if ($modal[0]) {
    
    $('body').attr({
      'aria-hidden': 'true'
    })
    .addClass('blur');
    
    $modal.css({
      display: 'block',
      top: window.pageYOffset
    });
    $modalOverlay.css({opacity: 0, display: 'block'});
    //$(window).resize();
    if (!page['page-works'].isSlider) {
      page['page-works'].setSlider();
    } else {
      // sp ⇔ pcで画像の高さが0pxになるので対処
      for (var i = 0; i < page['page-works'].childSlider.length; i++) {
        (function(local){
          setTimeout(function () {
            page['page-works'].childSlider[local].slick('setPosition');
          }, 10);
          setTimeout(function () {
            page['page-works'].childSlider[local].slick('setPosition');
          }, 20);
        }(i));
      }
    }
    
    var modalType = (page['page-works'].modalOpened.type === 'js-modal-web') ? 'webSlider' : 'graphicSlider';
    page['page-works'][modalType].slick('slickSetOption', page['page-works'].slideOptionOff);
    page['page-works'][modalType].slick('slickGoTo', page['page-works'].modalOpened.id);
    page['page-works'][modalType].slick('slickSetOption', page['page-works'].slideOptionOn);
    
    setTimeout(() => {
      $modal.addClass('open');
      $modalOverlay.animate({opacity: 1}, 500);
      $modal.find('.js-modal-close').focus();
    }, 0);
  }
});
$(document).on('click', '.js-modal-close', function(e) {
  var $opened = $('*[data-modal-target-type="' + page['page-works'].modalOpened.type + '"][data-modal-target-id="' + page['page-works'].modalOpened.id + '"]');
  var $modalOverlay = $('#js-modal-overlay');
  var $modal = $('.js-modal:visible');

  $('body').removeAttr('aria-hidden').removeClass('blur');
  
  $modal.one('transitionend', function () {
    $modal.css({display: ''});
  });
  $modal.removeClass('open');
  $modalOverlay.animate({opacity: 0}, 500, function () {
    $modalOverlay.css({display: ''});
  });
  
  if ($opened[0]) {
    $opened.focus();
  }
});
$(document).on('keydown', '.js-modal', function(e) {
  var $this = $(this);

  // esc key
  if (e.keyCode == 27 ) {
    $this.find('.js-modal-close').trigger('click');
  }
  // tab key
  if (e.keyCode == 9 ) {
    var $focusableItems = $this.find('*').filter(focusableElementsString).filter(':visible');
    var $focusedItem = $(document.activeElement);
    var focusedItemIndex = $focusableItems.index($focusedItem);
    var focusableItemsLength = $focusableItems.length;

    // first
    if (!e.shiftKey && (focusedItemIndex == focusableItemsLength - 1)) {
      $focusableItems.eq(0).focus();
      e.preventDefault();
    }
    // last
    if (e.shiftKey && (focusedItemIndex == 0)) {
      $focusableItems.eq(focusableItemsLength - 1).focus();
      e.preventDefault();
    }
  }
});
$(document).on('click', '#js-modal-overlay', function(e) {
  e.preventDefault();
  var $modal = $('.js-modal:visible');
  $modal.find('.js-modal-close').trigger('click');
});
$(document).on('keydown', '#js-modal-overlay', function(e) {
  var $modal = $('.js-modal:visible');
  // space or enter
  if (e.keyCode == 13 || e.keyCode == 32) { 
    e.preventDefault();
    $modal.find('.js-modal-close').trigger('click');
  }
});

$(document).on('click', '.js-index-works', function(e) {
  e.preventDefault();
  var $this = $(this);
  page['page-works'].modalOpened.type = $this.attr('data-modal-target-type');
  page['page-works'].modalOpened.id = $this.attr('data-modal-target-id');
  page['page-works'].modalOpened.pageInit = true;
  Barba.Pjax.goTo('/works/')
});


/*
 *
 *
 * page js
 *
 *
 */

// waypoints
var inView = {
  list: [],
  item: {
    covers: function () {
      $('.covers').each(function () {
        inView.list.push(new Waypoint({
        element: $(this),
        handler: function(direction) {
          $(this.element).addClass('anim');
        },
          offset: '55%'
        }));
      });
    },
    indexPage: function () {
      $('.js-anim_title').each(function () {
        var self = this;

        inView.list.push(new Waypoint({
        element: $(this),
        handler: function(direction) {
          var $wrap = $(self).closest('.top-ttl_area');
          setTimeout(function () {
            $wrap.find('.js-anim_bar.no-anim').removeClass('no-anim');
          }, 300);

          setTimeout(function () {
            $wrap.find('.js-anim_title2').find('.before-txt').find('.first-txt.no-anim').removeClass('no-anim');
            setTimeout(function () {
              $wrap.find('.js-anim_title').find('.before-txt').find('.first-txt.no-anim').removeClass('no-anim');
            }, 300);
          }, 600);


          setTimeout(function () {
            $wrap.find('.js-anim_title2').find('.before-txt').find('.no-anim').removeClass('no-anim');
            setTimeout(function () {
              $wrap.find('.js-anim_title').find('.before-txt').find('.no-anim').removeClass('no-anim');
            }, 300);
          }, 1300);

          setTimeout(function () {
            $wrap.find('.js-anim_title').find('.after-txt').removeClass('no-anim');
          }, 2200);


          this.destroy();
        },
          offset: '80%'
        }));
      });
      $('.js-anim_content').each(function () {
        inView.list.push(new Waypoint({
        element: $(this),
        handler: function(direction) {
          this.destroy();
        },
          offset: '80%'
        }));
      });
    },
    worksPage: function () {
      inView.list.push(new Waypoint({
      element: $('.works-view-list li').eq(0),
      handler: function(direction) {
        $('.works-view-list li').each(function (index, el) {
          var $this = $(this);
          setTimeout(function () {
            $this.addClass('anim');
          }, index * 150);
        });

      },
        offset: '80%'
      }));
    },
    servicePage: function () {
      $('.js-service-anim').each(function () {
        inView.list.push(new Waypoint({
        element: $(this),
        handler: function(direction) {
          $(this.element).addClass('anim');

          if (window.matchMedia("(min-width:768px)").matches) {
            TweenMax.fromTo(
              $(this.element).find('.service-bg-img'),
              1,
              {
                scaleX: 0,
                transformOrigin: '0 0'
              },
              {
                scaleX: 1,
                ease: 'easeOutQuart',
                delay: 1.3
              }
            );
            TweenMax.fromTo(
              $(this.element).find('.service-bg-img img'),
              1,
              {
                opacity: 0
              },
              {
                opacity: 1,
                delay: 2.3
              }
            );
          }

          TweenMax.fromTo(
            $(this.element).find('.bg-box1'),
            1.5,
            {
              x: '100%'
            },
            {
              x: '0%',
              ease: 'easeOutQuart',
              delay: 0.5
            }
          );
          TweenMax.fromTo(
            $(this.element).find('.bg-box2'),
            1,
            {
              scale: 0,
              transformOrigin: '0 0'
            },
            {
              scale: 1,
              ease: 'easeOutQuart',
              delay: 0.8
            }
          );

          this.destroy();
        },
          offset: '55%'
        }));
      });
    }
  },
  page: function (page) {
    switch (page){
      case 'page-index':
        inView.item.covers();
        inView.item.indexPage();
        break;
      case 'page-service':
        inView.item.covers();
        inView.item.servicePage();
        break;
      case 'page-works':
        inView.item.covers();
        inView.item.worksPage();
        break;
      case 'page-company':
        inView.item.covers();
        break;
      case 'page-privacy':
        inView.item.covers();
        break;
      case 'page-recruit':
        inView.item.covers();
        break;
      case 'page-contact':
        inView.item.covers();
        break;
    }
  },
  destroy: function () {
    for (var i = 0; i < this.list.length; i++) {
      this.list[i].destroy()
      this.list = [];
    }

    $('body').removeAttr('aria-hidden').removeClass('blur');
  }
};

var page = {};
page.htmlClass = '';
page.firstInit = true;
page.indexVisited = false;
page.init = function () {
  site.switchImg();
  site.switchTel();

  $('.js-anim').addClass('anim-hidden');
  page.firstInit = false;
}
page.titleAnim = function (cnt) {
  setTimeout(function () {
    $('.ttlanim').eq(0).addClass('anim');
  }, cnt);
  setTimeout(function () {
    $('.ttlanim').eq(1).addClass('anim');
  }, cnt + 500);
};
// index
page['page-index'] = {
  init: function () {
    var self = this;

    page['page-index'].loadingIn();

    page.init();
    $(window).trigger('resize');

    page['page-index'].setMainTitleAnim();
    page['page-index'].setTitleAnim();
    page['page-index'].setVideo();
    page['page-index'].setSvgIcon();
    page['page-index'].setRightNav();

    setTimeout(function () {
      page['page-index'].mainTitleAnim();
    }, (Utils.userAgent.isMobileDevice() || page.indexVisited) ? 0 : 4000);

    page.indexVisited = true;
  },
  setMainTitleAnim: function () {
    //TweenMax.set($('#main-visual').find('.tr-left'), {y: '-100%'});
    //TweenMax.set($('#main-visual').find('.tr-right'), {y: '100%'});
  },
  setTitleAnim: function () {

    $('.main-txt-wrap').append($($('.main-txt-wrap').html()).addClass('bg'));
    $('.js-no-anim').addClass('no-anim');

    $('.top-ttl_area').each(function () {
      $(this).find('.js-anim_title').after('<div class="title title-copy js-anim_title2">' + $(this).find('.js-anim_title').html() + '</div>');
    });

    $('.js-txt-width').each(function () {
      $(this).parent().width($(this).width());
    });


  },
  setVideo: function () {
    var video = $('#main-bg')[0];
    video.addEventListener('canplay', function(){
      // page['page-index'].loadingOut();
    });
    $('video').each(function () {
      if (!Utils.userAgent.isMobileDevice()) {
        $(this)[0].play();
      }
    });
  },
  setRightNav: function () {
    var $navList = $('#fix-nav a');
    var contentsPos = [];

    // 追随ナビゲーション アンカー
    var setContentsPos = function () {
      var _contentsPos = [];
      for (var i = 0; i < $navList.length; i++) {
        var targetContents = $navList.eq(i).attr('href');
        if(targetContents.charAt(0) == '#') {
          if ($(targetContents)[0]) {
            var targetContentsTop = Math.floor($(targetContents).offset().top);
            var targetContentsBottom = Math.floor(targetContentsTop + $(targetContents).outerHeight(true));
            _contentsPos.push([targetContentsTop, targetContentsBottom]);
          }
        }
      };
      contentsPos = _contentsPos;
    }

    // 位置チェック
    var currentCheck = function () {
      var windowScrolltop = $(window).scrollTop() + $(window).height()/2;
      for (var i = 0; i < contentsPos.length; i++) {
        if(contentsPos[i][0] <= windowScrolltop && contentsPos[i][1] > windowScrolltop) {
          $navList.parent().removeClass('active');
          $navList.eq(i).parent().addClass('active');
          break;
        }
      };
    }

    setContentsPos();

    $(window).on('load scroll.pageEvent', function(e) {
      currentCheck();
    });
  },
  setSvgIcon: function () {
    $('.js-svg-start').each(function () {
      var id = $(this).attr('id');
      var src = $(this).attr('data-src');
      new Vivus(id, {
        file: src,
        type: 'oneByOne',
        duration: 150,
        pathTimingFunction: Vivus.EASE_IN_OUT,
        onReady: function (myVivus) {}
      });
    });
  },
  mainTitleAnim: function () {
    setTimeout(function () {
      TweenMax.fromTo(
        $('#main-visual').find('.tr-left'),
        1,
        {
          y: '-100%',
        },
        {
          y: '0%',
          ease: 'easeOutQuart'
        }
      );
      TweenMax.fromTo(
        $('#main-visual').find('.tr-right'),
        1,
        {
          y: '100%',
        },
        {
          y: '0%',
          ease: 'easeOutQuart'
        }
      );
    }, 500);

    setTimeout(function () {

      setTimeout(function () {
        $('#main-visual').find('.main-v-txt.bg').find('.txt1').removeClass('no-anim');
      }, 0);
      setTimeout(function () {
        $('#main-visual').find('.main-v-txt').find('.txt1').removeClass('no-anim');
      }, 300);

      setTimeout(function () {
        $('#main-visual').find('.main-v-txt.bg').find('.ttl1').removeClass('no-anim');
      }, 1000);
      setTimeout(function () {
        $('#main-visual').find('.main-v-txt').find('.ttl1').removeClass('no-anim');
      }, 1400);

      setTimeout(function () {
        $('#main-visual').find('.main-v-txt.bg').find('.ttl2').removeClass('no-anim');
      }, 1600);
      setTimeout(function () {
        $('#main-visual').find('.main-v-txt').find('.ttl2').removeClass('no-anim');
      }, 1900);

      setTimeout(function () {
        $('#main-visual').find('.main-v-txt').find('.lead').addClass('anim on');
      }, 2800);

    }, 1000);


  },
  loadingIn: function () {
    if (!page.firstInit) {
      $('#js-loading').show().removeClass('out start');
      setTimeout(function () {
        $('#js-loading').addClass('start');
        $('.barba-container').css({opacity: 1});
      }, 0);
    } else {
      $('#js-loading').show();
      setTimeout(function () {
        $('#js-loading').addClass('start');
      }, 0);
    }

    if (page.indexVisited || Utils.userAgent.isMobileDevice()) {
      page['page-index'].loadingOut();
    } else {
      page['page-index'].movieWait = setTimeout(() => {
        page['page-index'].loadingOut();
      }, 3000);
    }
  },
  loadingOut: function () {
    page.titleAnim(0);

    if (Utils.userAgent.isMobileDevice()) {
      $('#js-loading').hide();
      $('#js-loading').addClass('out');
    } else {
      $('#js-loading').addClass('out');
      setTimeout(function () {
        $('#js-loading').hide();
      }, 800);
    }

    clearTimeout(page['page-index'].movieWait);
  },
  movieWait: 0,
  destroy: function () {
    $(window).off('.pageEvent');
  }
};

// works
page['page-works'] = {
  init: function () {
    page.init();
    page.titleAnim(200);
    // this.setSlider();

    setTimeout(function () {
      if (page['page-works'].modalOpened.pageInit) {
        var $modalBtn = $('.js-modal-button[data-modal-target-type="' + page['page-works'].modalOpened.type + '"][data-modal-target-id="' + page['page-works'].modalOpened.id + '"]');
        $modalBtn.trigger('click');
      }
      page['page-works'].modalOpened.pageInit = false;
    }, 50);
  },
  modalOpened: {
    type: '',
    id: '',
    pageInit: false
  },
  isSlider: false,
  setSlider: function () {
    var self = this;

    this.webSlider = $('#js-modal-web').find('.js-modal-outer-slider').slick({
      lazyLoad: 'ondemand',
      infinite: true,
      speed: 500
    });
    this.graphicSlider = $('#js-modal-graphic').find('.js-modal-outer-slider').slick({
      lazyLoad: 'ondemand',
      infinite: true,
      speed: 500
    });

    $('.js-modal-inner-slider').each(function () {
      var $childSlider = $(this);
      var childSliderLen = $childSlider.children('div').length;
      if (childSliderLen > 1) {
        self.childSlider.push(
          $childSlider.slick({
            lazyLoad: 'ondemand',
            infinite: true,
            vertical: true,
            verticalSwiping: true
          })
        );
      }
    });

    this.isSlider = true;
  },
  destroy: function () {
    if (this.webSlider) this.webSlider.slick('unslick');
    if (this.graphicSlider) this.graphicSlider.slick('unslick');
    for (var i = 0; i < this.childSlider.length; i++) {
      this.childSlider[i].slick('unslick');
    }
    this.childSlider = [];
    this.isSlider = false;
  },
  slideOptionOn: {
    infinite: true,
    speed: 500
  },
  slideOptionOff: {
    infinite: true,
    speed: 0
  },
  webSlider: '',
  graphicSlider: '',
  childSlider: []
};

// service
page['page-service'] = {
  init: function () {
    page.init();
    page.titleAnim(200);
  },
  destroy: function () {
  }
};
// recruit
page['page-recruit'] = {
  init: function () {
    page.init();
    page.titleAnim(200);
  },
  destroy: function () {
  }
};
// company
function initMap() {
  page['page-company'].map.init();
}

page['page-company'] = {
  init: function () {
    page.init();
    page.titleAnim(200);

    if (typeof google !== 'undefined' && google.maps) {
      initMap();
    } else {
      $('body').append('<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD4ua7NxMc4bp50bhWZx27zMCMn704LdXA&callback=initMap"></script>');
    }
  },
  destroy: function () {
  },
  map: {
    list: [
      {
        name: 'tokyo',
        mapInstance: undefined,
        markerInstance: undefined,
        mapPos: {
          lat: 35.65704344382545,
          lng: 139.73851919174194
        },
        markerPos: {
          lat: 35.65795876583687,
          lng: 139.73849773406982
        },
        zoom: 17
      },
      {
        name: 'sapporo',
        mapInstance: undefined,
        markerInstance: undefined,
        mapPos: {
          lat: 43.063558,
          lng: 141.352643,
        },
        markerPos: {
          lat: 43.063558,
          lng: 141.352643,
        },
        zoom: 16
      },
      {
        name: 'kyoto',
        mapInstance: undefined,
        markerInstance: undefined,
        mapPos: {
          lat: 34.988718,
          lng: 135.75652,
        },
        markerPos: {
          lat: 34.988718,
          lng: 135.75652,
        },
        zoom: 16
      }
    ],
    getMapOption: function (data) {
      return {
        center: data.mapPos,
        scrollwheel: false,
        zoom: data.zoom
      };
    },
    getIconPath: {
      path: 'M22.5,0C10.074,0,0,10.074,0,22.5C0,45.834,18.25,71,22.5,71C27,71,45,45.833,45,22.5C45,10.074,34.927,0,22.5,0z M22.5,35C15.596,35,10,29.404,10,22.5S15.596,10,22.5,10C29.403,10,35,15.596,35,22.5S29.403,35,22.5,35z',
      fillColor: '#f2d90d',
      fillOpacity: 1,
      scale: 0.8,
      strokeColor: '#000000',
      strokeWeight: 0.5
    },
    init: function () {
      for (var i = 0; i < this.list.length; i++) {
        this.list[i].mapInstance = new google.maps.Map(
          document.getElementById('map-' + this.list[i].name),
          this.getMapOption(this.list[i])
        );

        this.list[i].markerInstance = new google.maps.Marker({
          position: this.list[i].markerPos,
          // icon: this.getIconPath,
          draggable: false,
          map: this.list[i].mapInstance
        });
      }
    }
  }
};

var vm;
function mountVue() {
  vm = new Vue({
      el: '#form',
      computed: {
        isInput: function () {
          return this.display === 'input';
        },
        isPreview: function () {
          return this.display === 'preview';
        },
        isComplete: function () {
          return this.display === 'complete';
        },
        isSendError: function () {
          return this.display === 'error';
        }
      },
      data: function () {
        return {
          form: {
            purpose: {
              error: false,
              validate: '',
              required: true,
              value: ''
            },
            detail: {
              error: false,
              validate: '',
              required: false,
              value: ''
            },
            name: {
              error: false,
              validate: '',
              required: true,
              value: ''
            },
            company: {
              error: false,
              validate: '',
              required: false,
              value: ''
            },
            tel: {
              error: false,
              validate: '',
              required: true,
              value: ''
            },
            email: {
              error: false,
              validate: 'email',
              required: true,
              value: ''
            }
          },
          isAllRequiredItemsFilled: false,
          isError: false,
          display: 'input'
        }
      },
      methods: {
        checkRequiredItems: function () {
          var self = this;
          var isFilled = true;
          for (var data in this.form) {
            if (this.form.hasOwnProperty(data)) {
              if (this.form[data].required && !this.form[data].value) {
                isFilled = false;
              }
            }
          }
          self.isAllRequiredItemsFilled = isFilled;
        },
        onPreview: function () {
          var self = this;
          var isSubmit = true;
          for (var data in this.form) {
            if (this.form.hasOwnProperty(data)) {
              var isError = false;
              // required
              if (this.form[data].required && !this.form[data].value) {
                isSubmit = false;
                isError = true;
                this.form[data].errorText = '必須項目に入力してください';
              }
              if (this.form[data].required && this.form[data].validate === 'checkbox' && !this.form[data].value.length > 0) {
                isSubmit = false;
                isError = true;
                this.form[data].errorText = '必須項目に入力してください';
              }

              // other
              if (!isError && this.form[data].validate === 'email' && !this.form[data].value.match(/.+@.+\..+/)) {
                isSubmit = false;
                isError = true;
                this.form[data].errorText = '正しいメールアドレスを入力してください';
              }

              this.form[data].error = isError;
            }
          }
          if (!isSubmit) {
            this.isError = true;

            var headerHeight = $(window).width() > 767 ? 70 : 0;
            setTimeout(function () {
              $('body,html').animate({
                scrollTop: $('.is-error').eq(0).offset().top - headerHeight,
                easing: 'easeOutQuart'
              }, 600);
            }, 250);
          } else {
            displayTransition(function () {
              self.isError = false;
              self.display = 'preview';
            });
          }
        },
        onSubmit: function () {
          var self = this;
          var submitData = {
            "purpose": self.form.purpose.value,
            "detail": self.form.detail.value,
            "name": self.form.name.value,
            "company": self.form.company.value,
            "tel": self.form.tel.value,
            "email": self.form.email.value
          };

          $.ajax({
            url: 'https://8exugdrklj.execute-api.ap-northeast-1.amazonaws.com/prd',
            type: 'POST',
            data: JSON.stringify(submitData),
            dataType: 'text',
            headers: {
              'Content-Type': 'application/json'
            }
          }).
            done(function (response) {
              setTimeout(function () {
                $('#sending').remove();
                displayTransition(function () {
                  self.display = 'complete';
                });
              }, 1000);
            })
            .fail(function (error) {
              console.log(error);
              $('#sending').remove();
              self.display = 'error';
            })
            .always(function () {

            });

          setModal('送信中...');
        },
        onBack: function () {
          var self = this;
          displayTransition(function () {
            self.display = 'input';
          });
        }
      },
      created: function () {
      },
      mounted: function () {
        $('#form').animate({
          opacity: 1,
        }, 300);
      }
    });
}
function setModal(text) {
  $('#sending').remove();
  $('body').append('<div id="sending" style="opacity: 0;">\
          <div class="overlay"></div>\
          <div class="error-pop_box"><p>' + text + '</p></div>\
        </div>');
  $('#sending').animate({ opacity: 1 }, 300);
}
function displayTransition(callback) {
  var headerHeight = $(window).width() > 767 ? 70 : 0;
  $('#form').animate({ opacity: 0 }, 250, function () {
    if (callback) {
      callback();
    }

    $('body,html').animate({
      scrollTop: $('#form').offset().top - headerHeight,
      easing: 'easeOutQuart'
    }, 600);

    setTimeout(function () {
      $('#form').animate({ opacity: 1 }, 250);
    }, 400);
  });
}
// contact
page['page-contact'] = {
  init: function () {
    page.init();
    page.titleAnim(200);
    mountVue();
  },
  destroy: function () {
    vm.$destroy();
  }
};

// privacy
page['page-privacy'] = {
  init: function () {
    page.init();
    page.titleAnim(200);
  },
  destroy: function () {
  }
};

Barba.Dispatcher.on('linkClicked', function(htmlElement, mouseEvent) {
  $('html').addClass('page-out');
});
Barba.Dispatcher.on('initStateChange', function(current) {

});
Barba.Dispatcher.on('newPageReady', function(current, prev, container, raw) {
  // ga('send', 'pageview', window.location.pathname.replace(/^\/?/, '/') + window.location.search);

  page.htmlClass = current.namespace;

  inView.destroy();
  if (page[prev.namespace] && page[prev.namespace].destroy) {
    page[prev.namespace].destroy();
  }
});
Barba.Dispatcher.on('transitionCompleted', function(current, prev, container, raw) {
  $('html').removeClass('page-out');
  inView.page(current.namespace);
  if (page[current.namespace]) {
    page[current.namespace].init();
  }
});

var FadeTransition = Barba.BaseTransition.extend({
  start: function() {
    Promise
      .all([this.newContainerLoading, this.fadeOut()])
      .then(this.fadeIn.bind(this));
  },

  fadeOut: function() {
    var _this = this;
    var moveTime = window.pageYOffset === 0 ? 0 : 1000;
    var scroll = window.pageYOffset - 500;
    var moveScroll = (scroll >= 0) ? scroll : 0;

    $('#global-header').removeClass('is-fixed');
    $('html, body').animate({ scrollTop: moveScroll}, moveTime, 'easeOutQuart', function() {
      window.scrollTo(0, 0);
    });
    return $(this.oldContainer).animate({ opacity: 0 }, 1000,function() {
    }).promise();
  },

  fadeIn: function() {
    var _this = this;
    var $el = $(this.newContainer);

    var className = $('html').attr('class').replace(/page-(.*?)( |$)/g, '');
    $('html').attr('class', className).addClass(page.htmlClass);

    $(this.oldContainer).hide();

    $el.css({
      visibility : 'visible',
      opacity : 0
    });
    $el.find('.main-area').css({
      visibility : 'visible',
      opacity : 0,
      bottom: '50px'
    });


    // indexがまだ読み込まれていない状態ならローディングを入れる
    if (!page.indexVisited && page.htmlClass === 'page-index') {
      _this.done();
    } else {
      $el.find('.main-area').animate({ opacity: 1, bottom: 0 }, 1000, 'easeOutQuint', function() {
      });
      $el.animate({opacity: 1}, 600, 'easeOutQuart', function() {
        _this.done();
      });
    }
  }
});

Barba.Pjax.getTransition = function() {
  return FadeTransition;
};

$(function() {
  Barba.Pjax.start();
  Barba.Prefetch.init();
});
